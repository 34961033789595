#captcha-container {
  padding-top: 4%;
  max-width: 75%;
  margin: auto;
  text-align: center;
}

.storemapper-maplink.btn,
.btn.storemapper-map-directions,
#storemapper-go,
.storemapper-storelink {
  display: none !important;
}

#storemapper-zip {
  display: none !important;
}

#storemapper.search_on_top,
#storemapper-form,
.storemapper-form-inner,
.storemapper-dropdown {
  width: 80%;
}

#storemapper.search_on_top #storemapper-form {
  margin-top: -45px;
}

#storemapper #storemapper-form {
  background: transparent !important;
}

/* #storemapper,
#storemapper-form, */
.storemapper-dropdown-btn {
  padding: 10px !important;
  border: black 1px solid !important;
  border-radius: 0px !important;
  height: auto !important;
}

/* #storemapper,
#storemapper-form,
.storemapper-dropdown-btn, */
.caret {
  margin-left: 20vw !important;
}

div#storemapper.search_reset_enabled #storemapper-left {
  display: none !important;
}

div#storemapper #storemapper-left,
div#storemapper #storemapper-right {
  width: 100% !important;
}

#storemapper #storemapper-form .storemapper-dropdown-menu.active {
  width: 56% !important;
}

#filter-text {
}

#content-container {
  padding-top: 4%;
  max-width: 90%;
  margin: auto;
  text-align: center;
  display: inline;
  vertical-align: top;
  /* padding-left: 24px; */
  padding-left: 5%;
}

#modal-header-text {
  font-family: "Bellota", cursive;
  font-weight: 400;
  font-style: normal;
  padding-top: 8px;
  font-size: 3em;
}

#email-button {
  border-radius: 0;
  background-color: #818181;
  color: white;
  font-weight: 400;
  font-size: 1em;
  padding: 10px;
  border: none;
  /* margin-top: 5%; */
  /* display: inline-block;
  width: 25%; */
}

#email-button-img {
  max-width: 25%;
  padding-right: 5px;
  vertical-align: middle;
  padding-bottom: 4px;
}

/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */

.screener_btn {
  float: left;
  background: #3bbfad;
  color: #fff;
  text-align: center;
  font-size: 20px;
  width: 40%;
  margin-top: 40px;
  margin-left: 25px;
  padding: 10px 20px 10px 20px;
  border: none;
}

.screener_btn a:link {
  color: #f26c50;
  text-decoration: none;
}
.screener_btn a:visited {
  color: #f26c50;
  text-decoration: none;
}
.screener_btn a:hover {
  color: #9e6dae;
  text-decoration: none;
}
.screener_btn a:active {
  color: #f26c50;
  text-decoration: none;
}

.screener_btn2 {
  float: right;
  background: #007298;
  color: #fff;
  text-align: center;
  font-size: 20px;
  width: 40%;
  margin-top: 40px;
  margin-right: 25px;
  padding: 10px 20px 10px 20px;
  left: 0;
  border: none;
}

.screener_btn2 a:link {
  color: #fff;
  text-decoration: none;
}
.screener_btn2 a:visited {
  color: #fff;
  text-decoration: none;
}
.screener_btn2 a:hover {
  color: #fff;
  background: #3bbfad;
  text-decoration: none;
}
.screener_btn2 a:active {
  color: #fff;
  text-decoration: none;
}

/*
button:hover {background:#fff;
color:#9e6dae;}
*/
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 12000;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 100px auto;
  padding: 20px;
  background: #afe2e3;
  border-radius: 5px;
  width: 40%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 13000;
}

.popup h2 {
  margin-top: 0;
  color: #fff;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}
.popup .close:hover {
  color: #333333;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
  color: #ffffff;
  margin-top: 30px;
  font-size: 18px;
  text-align: center;
}

#my_popup {
  background-color: #afe2e3;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.popup_head {
  ont-family: "museo-sans", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 23px;
  line-height: 26px;
  color: #007298;
  text-align: center;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

body {
  /* font-size: 1em;
    line-height: 1.4;
	background-color: #afe2e3;*/
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ===== Initializr Styles ==================================================
   Author: Jonathan Verrecchia - verekia.com/initializr/responsive-template
   ========================================================================== */

body {
  font: 16px/26px Helvetica, Helvetica Neue, Arial;
  background-color: #ffffff;
}

.wrapper {
  width: 100%;
  /* margin: 0 5%;*/
}

/* ===================
    ALL: Orange Theme
   =================== */

.title {
  color: white;
}

/* ==============
    MOBILE: Menu
   ============== */
.navbar {
  position: relative;
  width: 100%;
  background-color: #18988b;
  height: 80px;
  margin-top: 0px;
  /*box-shadow: 0px 5px 5px #3a3a3a ;*/

  z-index: 100;
}

nav ul {
  margin: 0;
  padding: 0;
  z-index: 10000;
  text-align: center;
  padding-top: 25px;
}

nav a {
  display: inline;
  margin-bottom: 10px;
  font-family: "museo-sans", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  color: #e2ded3;
}
nav a:link {
  color: #e2ded3;
  text-decoration: none;
}
nav a:visited {
  color: #e2ded3;
  text-decoration: none;
}
nav a:hover {
  color: #ffffff;
  text-decoration: none;
}
nav a:active {
  color: #ffffff;
  text-decoration: none;
}

/* ==============
    MOBILE: Main
   ============== */

.button {
  background: #ffffff;
  color: #000000;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  font-size: 20px;
  width: 60%;
  text-decoration: none;
  margin-top: 0px;
  padding: 10px 20px 10px 20px;
  left: 0;
  border: 1px solid #000000;
}

.button:hover {
  color: #ffffff;
  background: #6f6259;
}

.button_alt {
  background: #ffffff;
  color: #000000;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  font-size: 20px;
  width: 60%;
  text-decoration: none;
  margin-top: 25px;
  padding: 10px 20px 10px 20px;
  top: 25px;
  left: 0;
  border: 1px solid #000000;
}

.button_alt:hover {
  color: #ffffff;
  background: #6f6259;
}

.btn_about_uniti {
  background: #b68fc2;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  margin-top: 25px;
  padding: 10px 20px 10px 20px;
  left: 0;
}

.btn_about_uniti:hover {
  color: #ffffff;
  background: #6f6259;
}

.btn_about_unifi {
  background: #63a8bf;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  margin-top: 25px;
  padding: 10px 20px 10px 20px;
  left: 0;
}

.btn_about_unifi:hover {
  color: #ffffff;
  background: #6f6259;
}

.btn_uniti_research {
  background: #94cb67;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  margin-top: 25px;
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  left: 0;
}

.btn_uniti_research:hover {
  color: #ffffff;
  background: #6f6259;
}

.btn_find_site {
  background: #ff7f83;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-decoration: none;
  margin-top: 25px;
  margin-left: 10px;
  padding: 10px 40px 10px 40px;
  left: 0;
}

.btn_find_site:hover {
  color: #ffffff;
  background: #6f6259;
}

.SCRButton {
  background-color: #18988b;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 16px 68px;
  text-decoration: none;
}
.SCRButton:hover {
  color: #ffffff;
  background: #6f6259;
}
.SCRButton:active {
  position: relative;
  top: 1px;
}

.main {
  padding: 0px 0;
}

.main article h1 {
  font-size: 2em;
}

.main aside {
  color: white;
  padding: 0px 5% 10px;
}

.footer-container {
  position: relative;
  padding: 3vw 0;
  width: 92%;
  margin-left: 3%;
  background-color: #fff;
}

/* ===============
    ALL: IE Fixes
   =============== */

.ie7 .title {
  padding-top: 20px;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

img,
embed,
object,
video {
  max-width: 100%;
}

.logo {
  float: left;
  top: 35px;
  margin-left: 0px;
  margin-top: 10px;
}

.mn {
  position: relative;
  width: 100%;
}

.mn2 {
  position: relative;
  width: 100%;
  top: -70px;
  z-index: -999;
}

.mnfaq {
  position: relative;
  width: 100%;
  /*height:300px;*/
}

.mn_w {
  float: left;
  width: 35%;
  margin-left: 0;
  padding-left: 0;
  padding-right: 15px;
  background-color: #fff;
}

/*------new---------*/
.copyholder_new {
  float: left;
  top: 0px;
  left: 0px;
  /*width:62%;*/
  width: 57%;
  margin-left: 2%;
  /*border-left: solid;
	border-left-color: #f6325a;
	border-left-width: 3px;*/
}

.copyholder_new2 {
  position: relative;
  display: inline-block;
  top: 0px;
  left: 0px;
  margin-left: 5%;
  margin-right: auto;
  width: 65%;
}

.copyholder_main {
  float: left;
  top: 0px;
  left: 0px;
  width: 70%;
  margin-left: 5%;
}

.CRDPUC_copyholder_int {
  position: relative;
  top: -50px;
  left: 0px;
  width: 60%;
  margin-left: 5%;
}

.legal_highlight_1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #94cb67;
  line-height: 40px;
}

.legal_highlight_2 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #63a8bf;
  line-height: 40px;
}

.CRDPUC_copyholder_legal {
  position: relative;
  top: -70px;
  left: 0px;
  width: 70%;
  margin-left: 5%;
}

.CRDPUC_copyholder_legal a:link {
  color: #ff7f83;
  text-decoration: underline;
}
.CRDPUC_copyholder_legal a:visited {
  color: #ff7f83;
  text-decoration: underline;
}
.CRDPUC_copyholder_legal a:hover {
  color: #000000;
  text-decoration: none;
}
.CRDPUC_copyholder_legal a:active {
  color: #ff7f83;
  text-decoration: underline;
}

.copyholder_new_legal {
  float: left;
  top: 0px;
  left: 0px;
  /*width:62%;*/
  width: 80%;
  margin-left: 2%;
  /*border-left: solid;
	border-left-color: #f6325a;
	border-left-width: 3px;*/
}

.copyholder_new_int {
  float: left;
  top: 0px;
  left: 0px;
  /*width:62%;*/
  width: 65%;
  /*border-right: solid;
	border-right-color: #75861b;
	border-right-width: 3px;*/
}

.copyholder_new_int_red {
  float: left;
  top: 0px;
  left: 0px;
  /*width:62%;*/
  width: 65%;
  /*border-right: solid;
	border-right-color: #f6325a;
	border-right-width: 3px;*/
}

.logoholder {
  float: left;
  width: 30%;
  margin-left: 1%;
  margin-right: auto;
  text-align: center;
}

.logoholder_int {
  float: right;
  width: 30%;
  margin-right: 1%;
  margin-left: auto;
  text-align: center;
}

.int_img {
  float: right;
  /*width:30%;*/
  margin-right: 1%;
  margin-left: auto;
  margin-top: 20px;
  text-align: center;
}

.mn_image_new_MOBILE {
  /* display: none; */
}
.mn_image_new_MOBILE2 {
  /* display: none; */
}
.mn_image_new {
  display: block;
  position: relative;
  width: 100%;
  top: 0px;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
  /*border-bottom:20px;
	border-bottom-color: #3bbfad;
	border-bottom-style: solid;
	/*box-shadow: 0px 5px 5px #3a3a3a ;*/
}

.mn_image_new_INT {
  display: block;
  position: relative;
  width: 100%;
  top: 0px;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
  border-bottom: 20px;
  border-bottom-color: #18988b;
  border-bottom-style: solid;
  /*box-shadow: 0px 5px 5px #3a3a3a ;*/
}

/*Saas mixin*/

.headerbar {
  position: relative;
  width: 45%;
  background-color: #18988b;
  /*
	border-top: 3px;
	border-top-color: #fff;
	border-top-style: solid;*/
}

.INTheaderbar {
  position: absolute;
  width: 50%;
  /*background-color: #007298;*/
  left: 0;
  bottom: -75px;
  border-top: 100px solid #007298;
  border-right: 40px solid transparent;
}

.INThead {
  position: relative;
  top: 50px;
  font-family: "museo-sans", serif;
  font-weight: 100;
  font-style: normal;
  font-size: 40px;
  line-height: 45px;
  color: #6f6259;
  text-align: center;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.INThead_highlight {
  color: #000000;
  font-weight: 700;
}

.head {
  display: inline-block;
  position: relative;
  top: -120px;
  font-family: "museo-sans", serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 30px;
  color: #3bbfad;
  text-align: right;
  width: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 2%;
}

.HDhighlight {
  color: #afe2e3;
}

.subhead {
  /*display: inline-block;*/
  position: relative;
  top: 0px;
  font-family: "Bellota", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
  color: #e2ded3;
  text-align: left;
  width: 60%;
  /*margin-top: 30px;
	/*margin-bottom: 30px;*/
  padding-left: 10%;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 0;
}

.highlight {
  color: #ffffff;
  font-family: "museo-sans", serif;
  font-weight: 500;
  font-style: normal;
}

.int_pics {
  position: relative;
  margin-top: -50px;
  margin-left: 20%;
  margin-right: auto;
  width: 60%;
}

.CRDPUCsubhead {
  position: relative;
  left: 5%;
  width: 40%;
  /* top: -70px; */
  top: -7vw;
  font-family: "Bellota", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #231f20;
}

.CRDPUCsubhead_high {
  font-family: "Bellota", cursive;
  font-weight: 700;
  font-style: normal;
}

.CRDPUChead {
  position: absolute;
  left: 5%;
  width: 38%;
  /* top: 110px; */
  top: 10vw;
  font-family: "Bellota", cursive;
  font-weight: 300;
  font-style: normal;
  /* font-size: 45px;
  line-height: 50px; */
  font-size: 4vw;
  line-height: 4vw;
  color: #231f20;
}

.CRDPUC_high_1 {
  font-family: "Bellota", cursive;
  font-weight: 700;
  font-style: normal;
  color: #b68fc2;
}

.CRDPUC_high_2 {
  font-family: "Bellota", cursive;
  font-weight: 700;
  font-style: normal;
  color: #94cb67;
}

.CRDPUC_high_3 {
  font-family: "Bellota", cursive;
  font-weight: 400;
  font-style: normal;
  color: #ff7f83;
}

.CRDPUChead_uniti {
  position: absolute;
  left: 5%;
  width: 40%;
  top: 140px;
  font-family: "Bellota", cursive;
  font-weight: 300;
  font-style: normal;
  font-size: 50px;
  line-height: 55px;
  color: #231f20;
}

.CRDPUChead_unifi {
  position: absolute;
  left: 5%;
  width: 40%;
  top: 140px;
  font-family: "Bellota", cursive;
  font-weight: 300;
  font-style: normal;
  font-size: 48px;
  line-height: 55px;
  color: #231f20;
}

.CRDPUChead_interior {
  position: absolute;
  left: 5%;
  width: 70%;
  top: 140px;
  font-family: "Bellota", cursive;
  font-weight: 300;
  font-style: normal;
  font-size: 50px;
  line-height: 55px;
  color: #231f20;
}

.CRDPUChead_legal {
  position: absolute;
  left: 5%;
  width: 70%;
  /* top: 180px; */
  top: 14vw;
  font-family: "Bellota", cursive;
  font-weight: 300;
  font-style: normal;
  /* font-size: 50px;
  line-height: 55px; */
  font-size: 4vw;
  line-height: 4vw;
  color: #231f20;
}

/*
.Fhead_highlight {
	font-family: 'Bellota', cursive;
	font-weight: 700;
	font-style: normal;
	color: #000;
}
*/
/*
.headerbar {display: block;
	position:absolute;
	bottom:0px;
width: 70%;

	bottom:0px;
	margin-right: 0px;
	margin-left: auto;
	background-color: #007298;
	font-family: 'museo-slab', serif;
	font-weight:500;
	font-style: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: right;
	padding-top: 10px;
	padding-bottom: 10px;
	z-index: 200;
}/*

/*.subhead {
	float: right;
	top:-50px;
	width: 50%;
	font-family: 'museo-slab', serif;
	font-weight:300;
	font-style: normal;
	font-size: 14px;
	line-height: 18px;
	color:#ffffff;



}*/

.imagedisc {
  position: absolute;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  top: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
}

.imagecopy_main {
  position: absolute;
  text-align: right;
  margin-left: 10%;
  margin-right: auto;
  top: 0px;
  color: #fff;
  font-family: "Arvil_Sans", sans-serif;
  font-weight: normal;
  font-size: 23px;
  line-height: 40px;
  left: 16px;
}

.imagecopy_int {
  position: absolute;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
  width: 100%;
  top: 0px;
  color: #fff;
  font-family: "Arvil_Sans", sans-serif;
  font-weight: normal;
  font-size: 23px;
  line-height: 32px;
}

.int_secthead {
  margin-left: 0px;
  font-family: "Arvil_Sans", sans-serif;
  font-weight: 300;
  font-size: 35px;
  line-height: 50px;
  text-transform: uppercase;
  color: #fff;
}

.imagehead1 {
  font-family: "Arvil_Sans", sans-serif;
  font-weight: normal;
  font-size: 75px;
  line-height: 75px;
  padding-left: 0px;
}

.imagesubhead1 {
  font-family: "Arvil_Sans", sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
}

.mn_image_new_mobint {
  /* display: none; */
}
/*----------new----------*/

.copyholder {
  float: right;
  top: 0px;
  right: 0px;
  width: 57%;
}

.holder {
  position: relative;
}

.btnholder {
  /*padding-left:40px;
	padding-right:40px;
	padding-top:25px;*/
  display: block;
  margin-bottom: 75px;
  margin-right: 5%;
  float: right;
  width: 80%;
  background: #ffffff;
  color: #18988b;
  padding: 10px 30px 10px;
  text-align: center;
  font-family: "museo-sans", serif;
  font-weight: 900;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.lg {
  position: absolute;
  margin-left: 8%;
  top: 0px;
  z-index: 1000;
  max-width: 15vw;
}

.lg_legal {
  position: absolute;
  margin-left: 8%;
  top: 30px;
  z-index: 1000;
}

.CRDPUCbody {
  color: #18988b;
  text-align: center;
  width: 75%;
  display: inline-block;
  /*
	padding-left:40px;
	padding-right:40px;*/
  /* padding-top: 25px; */
  /*font-size:18px;
	line-height:22px;*/
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
}

.bodycopy {
  color: #231f20;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/*
.bodycopy a:link {color:#6f6259; text-decoration:underline;}
.bodycopy a:visited {color:#6f6259; text-decoration:underline;}
.bodycopy a:hover {color:#18988b; text-decoration:none;}
.bodycopy a:active {color:#6f6259; text-decoration:underline;}

*/

#maincopy {
  padding-bottom: 10px;
}
#intcopy {
  padding-top: 40px;
}
#aboutcopy {
  padding-bottom: 245px;
}
#medicalcopy {
  padding-bottom: 400px;
}
#qualifycopy {
  padding-bottom: 400px;
}
#faqcopy {
  padding-bottom: 15px;
}

.bodyheader {
  font-family: "Arvil_Sans", sans-serif;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  color: #f26c50;
}

.int_secthead_red {
  font-family: "fertigo_proregular", sans-serif;
  font-weight: 300;
  font-size: 35px;
  line-height: 50px;
  text-transform: uppercase;
  color: #f6325a;
}

.inclusion {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #f6325a;
}

/*.sectionhead {
	font-family: 'Lato', sans-serif;
	font-weight:300;
	font-size:32px;
	line-height:90px;
	color:#767231;

}*/

.faqhead {
  font-size: 25px;
  font-weight: bold;
  color: #3bbfad;
}

.calloutbox_one {
  float: left;
  width: 28%;
  margin-left: 5%;
}

.calloutbox_two {
  float: left;
  width: 28%;
  margin-left: 2%;
}

.calloutbox_three {
  float: left;
  width: 28%;
  margin-left: 2%;
}

.callout_img {
  margin-bottom: 10px;
}

.sub_call_holder_mob {
  display: none;
}

.sub_call_holder {
  float: right;
  width: 30%;
  margin-right: 5%;
  margin-left: auto;
}

.sideholder {
  float: right;
  width: 30%;
  margin-right: 4%;
  margin-left: auto;
}

.sideimg {
  margin-top: 20px;
  text-align: center;
}

.subcallout {
  margin-top: 20px;
  margin-left: 20px;
  background: #ffffff;
  color: #18988b;
  padding: 10px 30px 10px;
  text-align: center;
  z-index: 99999;
  /*box-shadow: 5px 5px 5px #3a3a3a ;*/
}

.callout {
  margin-top: 50px;
  background: #ffffff;
  color: #18988b;
  padding: 30px 50px 20px;
  text-align: center;
  /*box-shadow: 5px 5px 5px #3a3a3a ;*/
}

.callout_mob {
  display: none;
}

.callout_headline {
  font-family: "museo-sans", serif;
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
.callout_body {
  /*margin-top: 25px;*/
  padding-top: 5px;
  font-size: 18px;
  line-height: 22px;
}

.sub_callout_headline {
  font-family: "museo-sans", serif;
  font-weight: 900;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.sub_callout_body {
  /*margin-top: 25px;*/
  padding-top: 5px;
  font-size: 18px;
  line-height: 22px;
}

.callout_main {
  margin-top: 0px;
  background: #94cb67;
  color: #18988b;
  padding: 25px 30px 25px;
  text-align: center;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */
  -khtml-border-radius: 10px; /* for old Konqueror browsers */
}

.callout_main_mob {
  display: none;
}

.callout_main_headline {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.callout_main_body {
  /*margin-top: 25px;*/
  padding-top: 5px;
  font-size: 16px;
  line-height: 20px;
  color: #918f90;
}

.callout_main3 {
  margin-top: 0px;
  background: #ff7f83;
  color: #18988b;
  padding: 25px 30px 25px;
  text-align: center;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */
  -khtml-border-radius: 10px; /* for old Konqueror browsers */
}

.callout_main3_mob {
  display: none;
}

.callout_main3_headline {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  padding-bottom: 8px;
}
.callout_main3_body {
  /*margin-top: 25px;*/
  padding-top: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #918f90;
}

.callout_main2 {
  margin-top: 0px;
  background: #b68fc2;
  color: #18988b;
  padding: 25px 30px 25px;
  text-align: center;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */
  -khtml-border-radius: 10px; /* for old Konqueror browsers */
}

.callout_main2_mob {
  display: none;
}

.callout_highlight {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #ffffff;
}

.callout_main2_headline {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.callout_main2_body {
  /*margin-top: 25px;*/
  padding-top: 0px;
  font-size: 16px;
  line-height: 20px;
  color: #918f90;
}

.callout_edit {
  font-family: "museo-sans", serif;
  font-weight: 500;
  font-style: normal;
  padding-top: 5px;
  font-size: 16px;
  line-height: 20px;
  color: #918f90;
}

.MLR {
  position: relative;
  width: 100%;
  top: 0px;
  text-align: left;
  font-size: 10px;
  line-height: 20px;
  color: #2f2f2f;
  margin: 0 auto;
  padding-top: 30px;
}

.studylink {
  position: relative;
  width: 90%;
  right: 0px;
  top: 10px;
  margin-left: 5%;
  margin-top: -10px;
  padding-top: 0px;
  color: #767231;
}

.studylink a:link {
  color: #000;
  text-decoration: underline;
}
.studylink a:visited {
  color: #000;
  text-decoration: underline;
}
.studylink a:active {
  color: #000;
  text-decoration: underline;
}
.studylink a:hover {
  color: #767231;
  text-decoration: none;
}

.JRD {
  float: right;
  padding-left: 20px;
  margin-left: 10px;
  z-index: 0;
}

.foot {
  position: relative;
  width: 100%;
  top: 0px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #918f90;
  margin: 0 auto;
  /*padding-bottom:50px;
	padding-top:15px;*/
}

.foot a:link {
  color: #918f90;
  text-decoration: none;
}
.foot a:visited {
  color: #918f90;
  text-decoration: none;
}
.foot a:active {
  color: #918f90;
  text-decoration: none;
}
.foot a:hover {
  color: #000000;
  text-decoration: underline;
}
/*
.head {position:absolute;
bottom:60px;
width:35%;
/*background-color:#5493c5;
opacity: 0.9;
filter: alpha(opacity=90); /* For IE8 and earlier */
/*
} */

.secthead {
  position: absolute;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 62px;
  text-align: right;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 60px;
  left: 0;
}

.secthead2 {
  position: absolute;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 62px;
  text-align: right;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 60px;
  left: 0;
}

.secthead3 {
  position: absolute;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 62px;
  text-align: right;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 60px;
  left: 0;
}

.mobimage {
  display: none;
}

.questions {
  position: relative;
  font-weight: bold;
  padding-top: 0px;
  padding-left: 0px;
  top: 0;
  font-size: 18px;
  line-height: 35px;
  color: #3bbfad;
}

.questions a:link {
  color: #3bbfad;
  text-decoration: none;
}
.questions a:visited {
  color: #3bbfad;
  text-decoration: none;
}
.questions a:hover {
  color: #007298;
  text-decoration: underline;
}
.questions a:active {
  color: #3bbfad;
  text-decoration: none;
}

aside p {
  color: #000;
  /*font-size:17px;*/
}

.mn_image_mob {
  display: none;
}

.mobimage {
  display: none;
}

.add-margin-top {
  margin-top: 5%;
}

/* .column {
  float: left;
  width: 50%;
} */

/* .left {
  width: 25%;
}

.right {
  width: 75%;
} */

/* Clear floats after the columns */
/* .row:after {
  content: "";
  display: table;
  clear: both;
} */

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (min-width: 480px) {
  /* ====================
    INTERMEDIATE: Menu
   ==================== */

  nav a {
    text-align: center;
    /*float: left;*/
    margin: 0px 5px;
    padding: 1px 1%;
    margin-bottom: 0;
  }

  nav li:first-child a {
    margin-left: 0px;
  }

  nav li:last-child a {
    margin-right: 0;
  }

  /* ========================
    INTERMEDIATE: IE Fixes
   ======================== */

  nav ul li {
    display: inline;
    text-align: center;
  }

  .oldie nav a {
    margin: 0 0.7%;
  }
}

@media only screen and (min-width: 768px) {
  /* ====================
    WIDE: CSS3 Effects
   ==================== */

  /* ============
    WIDE: Menu
   ============ */

  nav {
    text-align: center;
    width: 100%;
  }

  /* ============
    WIDE: Main
   ============ */

  .main article {
    float: left;
    width: 57%;
  }

  .main aside {
    float: right;
    width: 28%;
  }
}

@media only screen and (min-width: 1280px) {
  /* ===============
    Maximal Width
   =============== */

  .wrapper {
    /*width: 1026px; / * 1140px - 10% for margins */
    width: 1200px; /* 1280px - 80px for margins */
    margin: 0 auto;
  }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

#responsive-btn {
  display: inline-block;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
     * Don't show links for images, or javascript/internal links
     */

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

@media only screen and (min-width: 1280px) {
  .CRDPUChead {
    top: 110px;
    font-size: 45px;
    line-height: 50px;
  }

  .CRDPUCsubhead {
    top: -70px;
  }

  .copyholder_main {
    padding-bottom: 50px;
  }

  .footer-container {
    padding: 50px 0;
  }

  .CRDPUChead_legal {
    top: 180px;
    font-size: 50px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 1015px) {
  .CRDPUChead_legal {
    top: 16vw;
  }

  #storemapper.search_on_top,
  #storemapper-form,
  .storemapper-form-inner,
  .storemapper-dropdown {
    margin-left: 4vw;
  }
  #responsive-btn {
    width: 100%;
    padding: 16px;
  }

  .btn_uniti_research {
    margin-left: 0;
  }

  .btn_find_site {
    margin-left: 0;
  }

  /* .lg_legal {
    max-width: 15vw;
  } */

  #find-site-logo {
    max-width: 15vw;
  }

  .calloutbox_one {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 2vw;
  }

  .calloutbox_two {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 2vw;
  }

  .calloutbox_three {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 2vw;
  }

  #mobilebuttons {
    width: 100%;
    margin: auto;
  }

  .CRDPUCbody {
    width: 100%;
  }

  .CRDPUC_copyholder_int {
    width: 85%;
  }
}
