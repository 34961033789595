.nav-button {
  display: none;
} /* hide the navigation button by default */
.side {
  display: none;
} /* hide the navigation button by default */

@media only screen and (min-width: 0px) and (max-width: 1070px) {
  .IEDhead {
    left: 44%;
    width: 26%;
    top: 130px;
    font-size: 30px;
    line-height: 35px;
    color: #6f6259;
  }

  .button {
    padding: 8px;
    width: 100%;
  }

  .IEDhead_highlight {
    font-size: 38px;
    line-height: 43px;
    color: #000;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1030px) {
  .IEDhead {
    left: 42%;
    width: 28%;
    top: 110px;
    font-size: 30px;
    line-height: 35px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 38px;
    line-height: 43px;
    color: #000;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {
  nav a {
    font-size: 14px;
    line-height: 16px;
    margin: 0px 5px;
    padding: 1px 0.5%;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 950px) {
  .IEDhead {
    left: 42%;
    width: 29%;
    top: 100px;
    font-size: 28px;
    line-height: 33px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 34px;
    line-height: 39px;
    color: #000;
  }

  .INTheaderbar {
    position: absolute;
    width: 80%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .IEDhead {
    left: 42%;
    width: 29%;
    top: 80px;
    font-size: 24px;
    line-height: 29px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 32px;
    line-height: 37px;
    color: #000;
  }

  .JRD {
    width: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
  .popup {
    margin: 100px auto;
    padding: 20px;
    width: 75%;
  }

  .screener_btn {
    float: left;
    font-size: 20px;
    width: 100%;
    margin-top: 40px;
    margin-left: 0px;
    padding: 10px 20px 10px 20px;
  }

  .screener_btn2 {
    float: right;
    font-size: 20px;
    width: 100%;
    margin-top: 40px;
    margin-right: 0px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }

  .callout {
    margin-top: 50px;
    background: #ffffff;
    color: #18988b;
    padding: 30px 20px 20px;
    text-align: center;
  }

  .calloutbox_one {
    display: block;
    position: relative;
    width: 90%;
    margin-left: 5%;
  }

  .calloutbox_two {
    display: block;
    position: relative;
    width: 90%;
    margin-left: 5%;
  }

  .calloutbox_three {
    display: block;
    position: relative;
    width: 90%;
    margin-left: 5%;
  }

  .callout_main {
    /* margin-top: 30px; */
    /* background: #ffffff;
    color: #18988b; */
    padding: 20px 30px 20px;
    text-align: center;
  }

  .copyholder_main {
    float: left;
    top: 0px;
    left: 0px;
    width: 90%;
    margin-left: 5%;
    padding-bottom: 2vw;
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .IEDhead {
    left: 42%;
    width: 29%;
    top: 80px;
    font-size: 20px;
    line-height: 25px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 28px;
    line-height: 33px;
    color: #000;
  }

  .sideholder {
    display: none;
  }

  .copyholder_new {
    float: left;
    top: 0px;
    left: 0px;
    width: 95%;
    margin-left: 2%;
  }

  .copyholder_new2 {
    float: left;
    /*display: inline-block;*/
    top: 0px;
    left: 0px;
    margin-left: 2%;
    /*margin-right: auto;*/
    width: 95;
  }

  .callout_mob {
    display: block;
    /*margin-bottom: 75px;*/
    margin-right: 5%;
    float: right;
    width: 80%;
    background: #3bbfad;
    color: #fff;
    padding: 30px 30px 20px;
    text-align: center;
  }

  .subhead {
    position: relative;
    top: 0px;
    font-size: 15px;
    line-height: 21px;
    width: 55%;
    padding-left: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sub_call_holder {
    width: 35%;
    margin-right: 5%;
    margin-left: auto;
  }

  .subcallout {
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px 30px 10px;
  }

  /* Navigation Button
	-------------------------------------------------------- */

  .side {
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 50px;
    height: 35px;
    background: url({menu-icon-large_world2}), #ebebeb;
    background: url({menu-icon-large_world2}), #ebebeb;
    background: url({menu-icon-large_world2}), #ebebeb;
    background: url({menu-icon-large_world2}), #ebebeb;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px, 100%;
    cursor: pointer;
    border: 0 none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7) inset;
    border-radius: 5px;
    z-index: 999;
    text-indent: -9999px;
  }

  .nav-button {
    display: block;
    position: absolute;
    top: -40px;
    left: 7px;
    width: 50px;
    height: 35px;
    background: url({menu-icon-large}),
      -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({menu-icon-large}),
      -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({menu-icon-large}),
      -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({menu-icon-large}),
      -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background-color: #18988b;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 21px, 100%;
    cursor: pointer;
    border: 0 none;
    border-bottom: 2px solid #3dade2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7) inset;
    border-radius: 5px;
    z-index: 999;
    text-indent: -9999px;
  }
  .nav-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-button.open {
    background: url({close-icon-large}),
      -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({close-icon-large}),
      -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({close-icon-large}),
      -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background: url({close-icon-large}),
      -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    background-position: center center;
    background-color: #18988b;
    background-repeat: no-repeat;
    background-size: 21px, 100%;
  }

  /* Navigation Bar
	-------------------------------------------------------- */

  body {
    /* padding-top: 50px; */
  }

  .primary-nav {
    width: 100%;
    float: none;
    /* background-image: url(../images/ROSE_logo_mob.png); */
    background-repeat: no-repeat;
    background-position: top;
    margin-left: 50px;
    background-color: #18988b; /* change the menu color */
    display: block;
    height: 50px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    position: absolute;
    top: -50px;
    right: 0px;
    z-index: 998;
    clear: both;
  }
  .primary-nav li {
    display: none;
    width: 100%;
    font-family: Arial;
  }
  .primary-nav li a {
    display: block;
    width: 90%;
    padding: 20px 5%;
    font-size: 14px;

    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .primary-nav li a:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border-top-color: transparent;
  }
  .primary-nav > li:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  /* Toggle the navigation bar open  */

  .primary-nav.open {
    height: auto;
    padding-top: 50px;
  }
  .primary-nav.open li {
    display: block;
  }

  /* Submenus – optional .parent class indicates dropdowns */

  .primary-nav > li:hover > a {
    background: rgba(0, 0, 0, 0.5);
    border-bottom-color: transparent;
  }
  .primary-nav li.parent > a:after {
    content: "▼";
    color: rgba(255, 255, 255, 0.5);
    float: right;
  }
  .primary-nav li.parent > a:hover {
    background: rgba(0, 0, 0, 0.75);
  }
  .primary-nav li ul {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    border-top: 0 none;
    padding: 0;
  }
  .primary-nav li ul a {
    border: 0 none;
    font-size: 12px;
    padding: 10px 5%;
    font-weight: normal;
  }
  .primary-nav li:hover ul {
    display: block;
    border-top: 0 none;
  }

  /*--------------*/

  .navbar {
    height: 0;
  }

  .foot {
    position: relative;
    width: 90%;
    top: 0px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #2f2f2f;
    margin: 0 auto;
    /*padding-bottom:50px;
	padding-top:15px;*/
  }
}

@media only screen and (min-width: 0px) and (max-width: 680px) {
  .INThead {
    position: relative;
    top: 30px;
    font-size: 35px;
    line-height: 40px;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .IEDhead {
    left: 42%;
    width: 29%;
    top: 60px;
    font-size: 18px;
    line-height: 23px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 25px;
    line-height: 30px;
    color: #000;
  }

  .subhead {
    font-size: 15px;
    line-height: 21px;
    width: 50%;
    padding-left: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sub_call_holder {
    width: 40%;
    margin-right: 5%;
    margin-left: auto;
  }

  .subcallout {
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px 30px 10px;
  }

  .button {
    font-size: 16px;
    width: 60%;
    margin-top: 40px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CRDPUCsubhead {
    margin-top: 8vw;
    width: 90%;
  }
  .mn_image_new {
    /* display: none; */
  }
  .mn_image_new_MOBILE {
    display: block;
    position: relative;
    width: 100%;
    top: 0px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .IEDhead {
    left: 45%;
    width: 33%;
    top: 60px;
    font-size: 18px;
    line-height: 21px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 25px;
    line-height: 30px;
    color: #000;
  }

  .subhead {
    font-size: 14px;
    line-height: 19px;
    width: 50%;
    padding-left: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .INThead {
    top: 30px;
    font-size: 30px;
    line-height: 35px;
    color: #6f6259;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 510px) {
  .INThead {
    top: 10px;
    font-size: 25px;
    line-height: 30px;
    color: #6f6259;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subhead {
    font-size: 14px;
    line-height: 19px;
    width: 45%;
    padding-left: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sub_call_holder {
    width: 45%;
    margin-right: 5%;
    margin-left: auto;
  }

  .subcallout {
    margin-top: 20px;
    margin-left: 20px;
    padding: 10px 30px 10px;
  }

  .button {
    font-size: 15px;
    width: 60%;
    margin-top: 40px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 490px) {
  .IEDhead {
    left: 45%;
    width: 30%;
    top: 40px;
    font-size: 16px;
    line-height: 19px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {
  .subcallout {
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px 20px 10px;
  }

  .sub_callout_headline {
    font-size: 16px;
    line-height: 21px;
  }

  .button {
    font-size: 13px;
    width: 60%;
    margin-top: 40px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 390px) {
  .mn_image_new_MOBILE {
    display: none;
  }
  .mn_image_new_MOBILE2 {
    display: block;
    position: relative;
    width: 100%;
    top: 0px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .IEDhead {
    left: 55%;
    width: 44%;
    top: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #6f6259;
  }

  .IEDhead_highlight {
    font-size: 19px;
    line-height: 22px;
    color: #000;
  }

  .sub_call_holder {
    display: none;
  }

  .sub_call_holder_mob {
    display: block;
    position: relative;
    width: 90%;
    margin-left: 3%;
    margin-right: auto;
    padding-bottom: 20px;
  }

  .subhead {
    font-size: 15px;
    line-height: 21px;
    width: 85%;
    margin-left: 3%;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .button {
    font-size: 16px;
    width: 60%;
    margin-top: 40px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }

  .callout_mob {
    display: block;
    position: relative;
    margin-left: 5%;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 80%;
    background: #3bbfad;
    color: #fff;
    padding: 30px 30px 20px;
    text-align: center;
  }

  .btnholder {
    display: block;
    float: left;
    margin-bottom: 75px;
    margin-left: 2%;
    margin-right: auto;

    width: 80%;
    background: #ffffff;
    color: #18988b;
    padding: 10px 30px 10px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  .popup {
    margin: 100px auto;
    padding: 20px;
    width: 75%;
  }

  .screener_btn {
    float: left;
    font-size: 20px;
    width: 100%;
    margin-top: 40px;
    margin-left: 0px;
    padding: 10px 20px 10px 20px;
  }

  .screener_btn2 {
    float: right;
    font-size: 20px;
    width: 100%;
    margin-top: 40px;
    margin-right: 0px;
    padding: 10px 20px 10px 20px;
    left: 0;
  }

  .INThead {
    top: 10px;
    font-size: 18px;
    line-height: 23px;
    color: #6f6259;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .copyholder_new2 {
    float: left;
    /*display:block;*/
    top: 0px;
    left: 0px;
    margin-left: 2%;
    /*margin-right: auto;*/
    width: 90%;
  }

  .copyholder_new {
    float: left;
    top: 0px;
    left: 0px;
    width: 90%;
    margin-left: 2%;
  }

  .bodycopy {
    /* color: #18988b; */
    /* padding-left: 20px; */
    padding-right: 0px;
    padding-top: 25px;
    /*font-size:18px;
	line-height:22px;*/
    font-size: 1em;
    line-height: 1.4;
  }
}

/* End Mobile Styles */
